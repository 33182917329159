import { get } from './http'

export const indexData = (params) => {
  const url = '/mobile/index/data'
  return get(url, params)
}

export const share = () => {
  const url = '/mobile/index/app_share'
  return get(url)
}

export const wxShare = (params) => {
  const url = '/weixin/jsapi_config'
  return get(url, params)
}
